import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../../images/logo192_2.png';
import './style.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLine,
  faTiktok,
} from '@fortawesome/free-brands-svg-icons';
import Elevenicon from '../../images/payment_channels/7-Eleven.png';
import BigCIcon from '../../images/payment_channels/bigc.png';
import CounterIcon from '../../images/payment_channels/counter-service.png';
import JCBIcon from '../../images/payment_channels/jcb-icon.png';
import MastercardIcon from '../../images/payment_channels/mastercard-icon.png';
import VisaIcon from '../../images/payment_channels/visa-icon.png';
import KPlusIcon from '../../images/payment_channels/k-plus-logo.png';
import PromptPayIcon from '../../images/payment_channels/promptpay.png';

const Footer = () => {
  return (
    <footer className="wpo-site-footer">
      <div className="wpo-upper-footer">
        <div className="wpo-footer-top">
          <div className="container">
            <div className="row">
              {/* Logo */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                <div className="logo widget-title">
                  <img src={Logo} alt="logo" />
                </div>
              </div>
              {/* Social Media Icons */}
              <div className="col-lg-6 col-md-6 col-sm-6 col-12 custom-grid">
                <ul className="social-media-list">
                  <li>
                    <a
                      href="https://www.facebook.com/share/1Ays5gZHuZ/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faFacebookF} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.tiktok.com/@ogtix.asia?_t=8rMB7Zwk0GH&_r=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faTiktok} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://www.instagram.com/ogtix.asia/profilecard/?igsh=MTVkdHQzMXdrbXF3bw=="
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faInstagram} />
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://page.line.me/noy2348o?oat_content=url&openQrModal=true"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FontAwesomeIcon icon={faLine} />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        {/* Footer Middle */}
        <div className="footer-middle">
          <div className="container">
            <div className="row">
              {/* Contact Widget */}
              <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="widget market-widget wpo-service-link-widget">
                  <div className="widget-title">
                    <h3>Contact</h3>
                  </div>
                  <p>You Can Reach Us On Social Media</p>
                  <div className="contact-ft">
                    <ul>
                      <li>
                        <i className="fi ti-location-pin"></i>101/794 Moo 6, Rassada, Phuket
                      </li>
                      <li>
                        <i className="fi ti-mobile"></i>02-269-6999
                      </li>
                      <li>
                        <i className="fi flaticon-email"></i>bizzyness@ogtix.asia
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Useful Links Widget */}
              <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="widget link-widget">
                  <div className="widget-title">
                    <h3>Useful Links</h3>
                  </div>
                  <ul>
                    <li>
                      <Link to="/about">About Us</Link>
                    </li>
                    <li>
                      <Link to="/destination">Our Offers</Link>
                    </li>
                    <li>
                      <Link to="/contact">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="/blog">FAQ</Link>
                    </li>
                  </ul>
                </div>
              </div>
              {/* Payment Channels Widget */}
              <div className="col col-lg-3 col-md-6 col-sm-12 col-12">
                <div className="widget market-widget wpo-service-link-widget">
                  <div className="widget link-widget">
                    <div className="widget-title">
                      <h3>Payment Channels</h3>
                    </div>
                    <ul className="payment-channels-list">
                      <li>
                        <img src={VisaIcon} alt="Visa" className="payment-icon" />
                      </li>
                      <li>
                        <img src={MastercardIcon} alt="Mastercard" className="payment-icon" />
                      </li>
                      <li>
                        <img src={JCBIcon} alt="JCB" className="payment-icon" />
                      </li>
                      <li>
                        <img src={KPlusIcon} alt="K Plus" className="payment-icon" />
                      </li>
                      <li>
                        <img src={PromptPayIcon} alt="PromptPay" className="payment-icon" />
                      </li>
                      <li>
                        <img src={CounterIcon} alt="Counter Service" className="payment-icon" />
                      </li>
                      <li>
                        <img src={Elevenicon} alt="7-Eleven" className="payment-icon" />
                      </li>
                      <li>
                        <img src={BigCIcon} alt="Big C" className="payment-icon" />
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
              {/* Add other widgets if needed */}
            </div>
          </div>
        </div>
      </div>
      {/* Lower Footer */}
      <div className="wpo-lower-footer">
        <div className="container">
          <div className="row">
            <div className="col col-lg-6 col-md-6 col-12">
              <div className="term">
                <ul>
                  <li>
                    <Link to="/">{/*Privacy Policy*/}</Link>
                  </li>
                  <li>
                    <Link to="/">{/*Terms & Condition*/}</Link>
                  </li>
                  <li>
                    <Link to="/">{/*Cookies*/}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col col-lg-6 col-md-6 col-12">
              <p className="copyright">&copy; 2024 OG Tix. All rights reserved</p>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
