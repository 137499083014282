import React from 'react';
//import ContactForm from '../ContactFrom'
import './style.css'

const Contactpage = () => {

    return(
        <div id="Contact" className="contact-area section-padding">
            <div className="container">
                <div className="wpo-contact-info">
                    <div className="row">
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-internet"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>Office Address</span>
                                    </div>
                                </div>
                                <h2>101/794 Moo 6, Rassada, Phuket</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-email"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>Office Email</span>
                                    </div>
                                </div>
                                <h2>bizzyness@ogtix.asia</h2>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6 col-sm-12 col-12">
                            <div className="info-item">
                                <div className="info-wrap">
                                    <div className="info-icon">
                                        <i className="fi flaticon-null-1"></i>
                                    </div>
                                    <div className="info-text">
                                        <span>Office Phone</span>
                                    </div>
                                </div>
                                <h2>02-269-6999</h2>
                            </div>
                        </div>
                    </div>
                </div>
                
                
            </div>
        </div>
     )
        
}

export default Contactpage;
