// src/components/BlogSidebar/index.js

import React from 'react';
import { Link } from 'react-router-dom';

// Removed static imports for recent posts
// import rb1 from '../../images/blog/bangkok.jpeg';
// import rb2 from '../../images/blog/chiangmai.jpeg';
// import rb3 from '../../images/blog/waterfall.jpeg';

import inst1 from '../../images/destination/krabi.jpeg';
import inst2 from '../../images/destination/phiphi.jpeg';
import inst3 from '../../images/destination/udonthani.jpeg';
import inst4 from '../../images/destination/ranong.jpeg';
import inst5 from '../../images/destination/surathani.jpg';
import inst6 from '../../images/destination/sukhothai.jpeg';

import about from '../../images/blog/profile.png';
import bicon from '../../images/boats.jpeg';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLine, faTiktok } from '@fortawesome/free-brands-svg-icons';

import blogData from '../../data/blogData'; // Importing blogData.js

import './style.css';

const BlogSidebar = (props) => {

    const ClickHandler = () => {
        window.scrollTo(10, 0);
    }

    // Function to get the latest 3 posts
    const getRecentPosts = () => {
        // Assuming each blog post has a 'date' property in a format that can be parsed by Date
        const sortedPosts = [...blogData].sort((a, b) => new Date(b.date) - new Date(a.date));
        return sortedPosts.slice(0, 3);
    }

    const recentPosts = getRecentPosts();

    return (
        <div className={`col col-lg-4 col-12 ${props.blLeft}`}>
            <div className="wpo-blog-sidebar">
                {/* Profile Widget */}
                <div className="widget profile-widget">
                    <div className="profile-img">
                        <img src={about} alt="Admin Profile" />
                        <h2>Admin</h2>
                        <p>Hi! Here is our blog. Read our posts and be with us.</p>
                    </div>
                    <div className="pro-social">
                        <ul>
                            <li>
                                <a
                                    href="https://www.facebook.com/share/1Ays5gZHuZ/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={faFacebookF} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.tiktok.com/@ogtix.asia?_t=8rMB7Zwk0GH&_r=1"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={faTiktok} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://www.instagram.com/ogtix.asia/profilecard/?igsh=MTVkdHQzMXdrbXF3bw=="
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={faInstagram} />
                                </a>
                            </li>
                            <li>
                                <a
                                    href="https://page.line.me/noy2348o?oat_content=url&openQrModal=true"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    <FontAwesomeIcon icon={faLine} />
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>

                {/* Categories Widget */}
                <div className="widget category-widget">
                    <h3>Categories</h3>
                    <ul>
                        <li><Link onClick={ClickHandler} to="/blog-details">Vacational Plan<span>(0)</span></Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details">Thailand Tour <span>(0)</span></Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details">Guide Information<span>(0)</span></Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details">Travelling <span>(3)</span></Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details">Hotels in Destinations <span>(0)</span></Link></li>
                    </ul>
                </div>

                {/* Recent Posts Widget */}
                <div className="widget recent-post-widget">
                    <h3>Recent Posts</h3>
                    <div className="posts">
                        {recentPosts.map(post => (
                            <div className="post" key={post.id}>
                                <div className="img-holder">
                                    <img src={post.image} alt={post.title} />
                                </div>
                                <div className="details">
                                    <h4>
                                        <Link onClick={ClickHandler} to={`/blog-details/${post.id}`}>
                                            {post.title}
                                        </Link>
                                    </h4>
                                    <span className="date">{post.date}</span>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>

                {/* Destinations Widget */}
                <div className="widget instagram">
                    <h3>Destinations</h3>
                    <ul className="d-flex">
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst1} alt="Krabi" /></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst2} alt="Phiphi" /></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst3} alt="Udon Thani" /></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst4} alt="Ranong" /></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst5} alt="Surathani" /></Link></li>
                        <li><Link onClick={ClickHandler} to="/destination"><img src={inst6} alt="Sukhothai" /></Link></li>
                    </ul>
                </div>

                {/* Tags Widget */}
                <div className="widget tag-widget">
                    <h3>Tags</h3>
                    <ul>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Travelling</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Hotel</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Restaurant</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Destination</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Thailand Tour</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Hotel Room</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Spa</Link></li>
                        <li><Link onClick={ClickHandler} to="/blog-details-left">Guide</Link></li>
                    </ul>
                </div>

                {/* Get In Touch Widget */}
                <div className="widget get-intouch">
                    <div className="get-item">
                        <img src={bicon} alt="Boat Icon" />
                        <h2>Let’s start your dreamy journey</h2>
                        <Link onClick={ClickHandler} className="theme-btn" to="/contact">Get in touch</Link>
                    </div>
                </div>
            </div>
        </div>
    )

}

export default BlogSidebar;
