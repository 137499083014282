import React from 'react'
import {Link} from 'react-router-dom'
import ab1 from '../../images/about/temple3.jpeg'
import './style.css'

const About = (props) => {
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
     }
    return(
        <div className="wpo-about-area section-padding">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-7 col-md-12 col-sm-12">
                        <div className="wpo-about-img">
                            <img src={ab1} alt=""/>
                        </div>
                    </div>
                    <div className="col-lg-5 col-md-12 colsm-12">
                        <div className="wpo-about-text">
                            <div className="wpo-section-title">
                                <span></span>
                                <h2>Best Deals <br/> For Your Tickets </h2>
                            </div>
                            <p>Explore Thailand with ease using OGTix.asia, the premier bus ticket booking experience in Asia! 

Book tickets in minutes, and access the best routes to the top destinations in Thailand. With trusted operators, secure payments, and unbeatable convenience, OGTix.asia makes your journey across Thailand smooth, affordable, and stress-free.

Your next Adventure is a Bus Ride Away...</p>
                            <div className="btns">
                                <Link onClick={ClickHandler} to="/about" className="theme-btn-s2">More About Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default About;