import React from "react";
import styles from "./style.css"; // Using CSS Modules

const SearchSection = (props) => {
  return (
    <div className={styles.searchSection}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <div className={styles.searchWrapper}>
              <h2 className={styles.searchHeader}>Know Where You Are Going?</h2>
              <div
                id="busx-search-form"
                data-url="https://booking.ogtix.asia"
                data-appkey="ddd7651cbb6a2be6465bbda66d048e61"
                data-locale="en_us"
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchSection;
