import React, {Fragment} from 'react';
import Navbar from '../../components/Navbar'
import PageTitle from '../../components/pagetitle'
import Footer from '../../components/footer'
import Scrollbar from '../../components/scrollbar'
import Destination3 from '../../components/Destination3'



const DestinationPage2 =() => {
    return(
        <Fragment>
            <Navbar/>
            <PageTitle pageTitle={'All Destinations'} pagesub={'All Destinations'}/> 
            <Destination3/>
            <Footer/>
            <Scrollbar/>
        </Fragment>
    )
};
export default DestinationPage2;
