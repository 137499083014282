// src/components/BlogDetails/index.js

import React from 'react';
import { Link, useParams } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar';
import './style.css';
import blogData from '../../data/blogData'; // Import dynamic blog data
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebookF, faInstagram, faLine, faTiktok } from '@fortawesome/free-brands-svg-icons';

const BlogDetails = (props) => {
  const { id } = useParams(); // Extract the dynamic id from the URL
  const blog = blogData.find((b) => b.id.toString() === id); // Find the corresponding blog post

  const submitHandler = (e) => {
    e.preventDefault();
    // Implement comment submission logic here if needed
  };

  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  if (!blog) {
    return (
      <section className="wpo-blog-single-section section-padding">
        <div className="container">
          <div className="row">
            <div className={`col col-lg-8 col-12 ${props.blRight}`}>
              <div className="wpo-blog-content clearfix">
                <div className="post">
                  <h2>Blog Not Found</h2>
                  <p>The blog post you're looking for does not exist.</p>
                  <Link onClick={ClickHandler} to="/blog">Back to Blog</Link>
                </div>
              </div>
            </div>
            <BlogSidebar blLeft={props.blLeft} />
          </div>
        </div>
      </section>
    );
  }

  return (
    <section className="wpo-blog-single-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content clearfix">
              <div className="post">
                <div className="entry-media">
                  <img src={blog.image} alt={blog.title} loading="lazy" />
                </div>
                <ul className="entry-meta">
                  <li>
                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>
                      By {blog.author}
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>
                      {blog.date}
                    </Link>
                  </li>
                </ul>
                <h2>{blog.title}</h2>
                <div dangerouslySetInnerHTML={{ __html: blog.content }}></div> {/* Render HTML content */}
                
                <div className="tag-share clearfix">
                  <div className="tag">
                    <ul>
                      {blog.category.map((cat, index) => (
                        <li key={index}>
                          <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>{cat}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="share">
                    <ul>
                      <li>
                        <a
                          href="https://www.facebook.com/share/1Ays5gZHuZ/"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faFacebookF} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.tiktok.com/@ogtix.asia?_t=8rMB7Zwk0GH&_r=1"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faTiktok} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/ogtix.asia/profilecard/?igsh=MTVkdHQzMXdrbXF3bw=="
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faInstagram} />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://page.line.me/noy2348o?oat_content=url&openQrModal=true"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FontAwesomeIcon icon={faLine} />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>

                {/* Author Box */}
                <div className="author-box">
                  <div className="author-avatar">
                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`} target="_blank">
                      <img src={blog.image} alt={blog.author} loading="lazy" />
                    </Link>
                  </div>
                  <div className="author-content">
                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`} className="author-name">
                      {blog.author}
                    </Link>
                    <p>Webmaster of the site and dedicated traveler of Thailand</p>
                    <div className="author-btn">
                      <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>All Posts From Author</Link>
                    </div>
                  </div>
                </div>

                {/* Navigation to Previous and Next Posts */}
                <div className="more-posts clearfix">
                  <div className="previous-post">
                    {/* Implement logic to find the previous post based on current id */}
                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id - 1}`}>
                      <span className="post-control-link">Previous</span>
                    </Link>
                  </div>
                  <div className="next-post">
                    {/* Implement logic to find the next post based on current id */}
                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id + 1}`}>
                      <span className="post-control-link">Next post</span>
                    </Link>
                  </div>
                </div>

                {/* Comments Section */}
                {blog.comments && blog.comments.length > 0 && (
                  <div className="comments-area">
                    <div className="comments-section">
                      <h3 className="comments-title">Comments</h3>
                      <ol className="comments">
                        {blog.comments.map((comment) => (
                          <li key={comment.id} className="comment">
                            <div className="comment-main-area">
                              <div className="comment-wrapper">
                                <div className="comments-meta">
                                  <h4>
                                    {comment.author} <span className="comments-date">{comment.date}</span>
                                  </h4>
                                </div>
                                <div className="comment-area">
                                  <p>{comment.content}</p>
                                  <div className="comments-reply">
                                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`} className="comment-reply-link">
                                      <i className="fa fa-reply" aria-hidden="true"></i><span> Reply</span>
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </li>
                        ))}
                      </ol>
                    </div>
                    {/* Comment Form */}
                    <div className="comment-respond">
                      <h3 className="comment-reply-title">Leave a Comment</h3>
                      <form onSubmit={submitHandler} method="post" id="commentform" className="comment-form">
                        <div className="form-inputs">
                          <input placeholder="Name" type="text" required />
                          <input placeholder="Email" type="email" required />
                          <input placeholder="Website" type="url" />
                        </div>
                        <div className="form-textarea">
                          <textarea id="comment" placeholder="Write Your Comments..." required></textarea>
                        </div>
                        <div className="form-submit">
                          <input id="submit" value="Reply" type="submit" />
                        </div>
                      </form>
                    </div>
                  </div>
                )}
                {/* End of Comments Section */}
              </div>
            </div>
            <BlogSidebar blLeft={props.blLeft} />
          </div>
        </div>
      </div>
    </section>
  );
};

export default BlogDetails;
