// src/components/BlogList/index.js

import React from 'react';
import { Link } from 'react-router-dom';
import BlogSidebar from '../BlogSidebar';
import VideoModal from '../../components/ModalVideo';
import './style.css';
import blogData from '../../data/blogData'; // Import the dynamic blog data

const BlogList = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <section className="wpo-blog-pg-section section-padding">
      <div className="container">
        <div className="row">
          <div className={`col col-lg-8 col-12 ${props.blRight}`}>
            <div className="wpo-blog-content">
              {blogData.map((blog) => (
                <div key={blog.id} className={`post format-${blog.video ? 'video' : 'standard-image'}`}>
                  <div className="entry-media">
                    <img src={blog.image} alt={blog.title} />
                    {blog.video && <VideoModal />} {/* Conditionally render VideoModal */}
                  </div>
                  <ul className="entry-meta">
                    <li>
                      <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>
                        By {blog.author}
                      </Link>
                    </li>
                    <li>
                      <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>
                        {blog.date}
                      </Link>
                    </li>
                  </ul>
                  <h3>
                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>
                      {blog.title}
                    </Link>
                  </h3>
                  <p>{blog.summary}</p>
                  <div className="entry-bottom">
                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`} className="read-more">
                      View More...
                    </Link>
                    <ul>
                      <li>
                        <i className="fi flaticon-heart"></i> {blog.comments}
                      </li>
                      <li>
                        <i className="fi flaticon-share"></i>
                      </li>
                    </ul>
                  </div>
                  <div className="blog-thumb-badge">
                    <div className="blog-thumb-text">
                      <span>{blog.category}</span>
                    </div>
                  </div>
                </div>
              ))}

              {/* Pagination */}
              <div className="pagination-wrapper pagination-wrapper-left">
                <ul className="pg-pagination">
                  <li>
                    <Link onClick={ClickHandler} to="/blog" aria-label="Previous">
                      <i className="fi ti-angle-left"></i>
                    </Link>
                  </li>
                  {/* Assuming pagination is static for minimal changes */}
                  <li className="active">
                    <Link onClick={ClickHandler} to="/blog">
                      1
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/blog">
                      2
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/blog">
                      3
                    </Link>
                  </li>
                  <li>
                    <Link onClick={ClickHandler} to="/blog" aria-label="Next">
                      <i className="fi ti-angle-right"></i>
                    </Link>
                  </li>
                </ul>
              </div>
              {/* End of Pagination */}
            </div>
          </div>
          <BlogSidebar blLeft={props.blLeft} />
        </div>
      </div>
    </section>
  );
};

export default BlogList;
