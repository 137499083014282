import React, { Component } from 'react';
import destinations from '../../data/destinationsData';
import './style.css';

class Destination2 extends Component {
  getOrigin(title) {
    if (!title || typeof title !== 'string') {
      console.error(`Invalid title format: ${title}`);
      return 'Unknown';
    }
    if (!title.includes('↔')) {
      console.warn(`Title does not contain the expected separator: ${title}`);
      return title.trim();
    }
    return title.split('↔')[0].trim();
  }

  groupDestinations(destinations) {
    return destinations.reduce((acc, dest) => {
      if (!dest || !dest.Title) {
        console.warn('Skipping destination with invalid data:', dest);
        return acc;
      }
      const origin = this.getOrigin(dest.Title);
      if (!acc[origin]) {
        acc[origin] = [];
      }
      acc[origin].push(dest);
      return acc;
    }, {});
  }

  generateId(str) {
    return str
      .toLowerCase()
      .replace(/\s+/g, '-') // Replace spaces with hyphens
      .replace(/[^\w-]+/g, '') // Remove all non-word characters except hyphens
      .replace(/--+/g, '-') // Replace multiple hyphens with a single hyphen
      .replace(/^-+/, '') // Trim hyphens from the start
      .replace(/-+$/, ''); // Trim hyphens from the end
  }

  handleClick = () => {
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  render() {
    if (!destinations || destinations.length === 0) {
      return <div className="destination-area section-padding">No destinations available.</div>;
    }

    // Filter destinations to include only the specified origins
    const allowedOrigins = ['Bangkok', 'Phuket', 'Chiang Mai', 'Krabi'];
    const filteredDestinations = destinations.filter((dest) => {
      const origin = this.getOrigin(dest.Title);
      return allowedOrigins.includes(origin);
    });

    const groupedDestinations = this.groupDestinations(filteredDestinations);
    const originCities = Object.keys(groupedDestinations);

    return (
      <div className="destination-area section-padding">
        <div className="container">
          <div className="col-12">
            <div className="wpo-section-title text-center">
              <span></span>
              <h2>Choose Your Favorite</h2>
            </div>
          </div>

          <div className="origin-navigation">
            <ul className="origin-list">
              {originCities.map((origin, index) => {
                const originId = this.generateId(origin);
                return (
                  <li key={index} className="origin-list-item">
                    <a href={`#${originId}`} className="origin-link">From {origin}</a>
                  </li>
                );
              })}
            </ul>
          </div>

          {originCities.map((origin, groupIndex) => {
            const originId = this.generateId(origin);
            return (
              <div className="destination-group" key={groupIndex} id={originId}>
                <div className="row">
                  <div className="col-12">
                    <h3 className="group-title">From {origin}</h3>
                  </div>
                </div>
                <div className="row">
                  {groupedDestinations[origin].map((dest, index) => (
                    <div className="col-sm-6 col-md-4 col-lg-3 mb-4" key={index}>
                      <a
                        href={dest.link}
                        target="_blank"
                        rel="noopener noreferrer"
                        onClick={this.handleClick}
                        className="destination-link"
                      >
                        <div className="destination-item">
                          <div className="destination-img">
                            <img
                              src={dest.DImg || 'default-placeholder.png'}
                              alt={dest.Title || 'Destination'}
                            />
                          </div>
                          <div className="destination-content">
                            <div className="content-left">
                              <h5 className="destination-title">{dest.Title || 'Unknown Destination'}</h5>
                              <small className="destination-date">{dest.Date || 'Unknown Date'}</small>
                            </div>
                            <div className="content-right">
                              <h5 className="destination-price">{dest.price || 'N/A'}</h5>
                              <span className="destination-starting">Starting</span>
                            </div>
                          </div>
                        </div>
                      </a>
                    </div>
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    );
  }
}

export default Destination2;
