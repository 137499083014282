import React, { Component } from 'react';
import { Collapse, CardBody, Card } from 'reactstrap';
import { Link } from 'react-router-dom';
import './style.css';

const menus = [
  {
    id: 1,
    title: 'Home',
    link: '/home',
  },
  {
    id: 2,
    title: 'Destinations',
    link: '/destination',
    submenu: [
      {
        id: 21,
        title: 'Popular Destinations',
        link: '/destination',
      },
      {
        id: 22,
        title: 'All Destinations',
        link: '/destination2',
      },
    ],
  },
  {
    id: 3,
    title: 'OG HOT PIX',
    link: '/destination',
    submenu: [
      {
        id: 31,
        title: 'Bangkok-Phuket',
        link: 'https://booking.ogtix.asia/bus/bangkok/phuket/6056',
      },
      {
        id: 32,
        title: 'Bangkok-Surathani',
        link: 'https://booking.ogtix.asia/bus/bangkok/surat-thani/6119',
      },
      {
        id: 33,
        title: 'Bangkok-Chiang Mai',
        link: 'https://booking.ogtix.asia/bus/bangkok/chiang-mai/3494',
      },
      {
        id: 34,
        title: 'Bangkok-Koh Chang',
        link: 'https://booking.ogtix.asia/bus/suvarnabhumi-airport/koh-chang/178375',
      },
      {
        id: 35,
        title: 'Bangkok-Phrae',
        link: 'https://booking.ogtix.asia/bus/bangkok/phrae/890',
      },
    ],
  },
  {
    id: 4,
    title: 'Blog',
    link: '/blog',
    submenu: [
      {
        id: 41,
        title: 'Blog',
        link: '/blog-fullwidth',
      },
    ],
  },
  {
    id: 5,
    title: 'Contact',
    link: '/contact',
  },
];

export default class MobileMenu extends Component {
  state = {
    isMenuShow: false,
    isOpen: 0,
  };

  menuHandler = () => {
    this.setState({
      isMenuShow: !this.state.isMenuShow,
    });
  };

  setIsOpen = (id) => () => {
    this.setState({
      isOpen: id === this.state.isOpen ? 0 : id,
    });
  };

  render() {
    const { isMenuShow, isOpen } = this.state;

    const ClickHandler = () => {
      window.scrollTo(10, 0);
    };

    // Helper function to determine if the link is external
    const isExternalLink = (url) => {
      return url.startsWith('http://') || url.startsWith('https://');
    };

    return (
      <div>
        <div className={`mobileMenu ${isMenuShow ? 'show' : ''}`}>
          <ul className="responsivemenu">
            {menus.map((item) => {
              return (
                <li key={item.id}>
                  {item.submenu ? (
                    <p onClick={this.setIsOpen(item.id)}>
                      {item.title}
                      {item.submenu ? (
                        <i className="fa fa-angle-right" aria-hidden="true"></i>
                      ) : (
                        ''
                      )}
                    </p>
                  ) : (
                    <Link to={item.link}>{item.title}</Link>
                  )}
                  {item.submenu ? (
                    <Collapse isOpen={item.id === isOpen}>
                      <Card>
                        <CardBody>
                          <ul>
                            {item.submenu.map((submenu) => (
                              <li key={submenu.id}>
                                {isExternalLink(submenu.link) ? (
                                  <a
                                    href={submenu.link}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    className="active"
                                  >
                                    {submenu.title}
                                  </a>
                                ) : (
                                  <Link
                                    onClick={ClickHandler}
                                    className="active"
                                    to={submenu.link}
                                  >
                                    {submenu.title}
                                  </Link>
                                )}
                              </li>
                            ))}
                          </ul>
                        </CardBody>
                      </Card>
                    </Collapse>
                  ) : (
                    ''
                  )}
                </li>
              );
            })}
          </ul>
        </div>

        <div className="showmenu" onClick={this.menuHandler}>
          <i className="fa fa-bars" aria-hidden="true"></i>
        </div>
      </div>
    );
  }
}
