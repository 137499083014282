// src/components/BlogSection/index.js

import React from 'react';
import { Link } from 'react-router-dom';
import './style.css';
import blogData from '../../data/blogData'; // Import dynamic blog data

const BlogSection = (props) => {
    
    const ClickHandler = () =>{
        window.scrollTo(10, 0);
    }

    // Fetch the latest 3 blog posts
    const latestBlogs = blogData.slice(-3).reverse(); // Adjust slicing as needed

    return(
        <div className="blog-area ptb-100-70">
            <div className="container">
                <div className="col-12">
                    <div className="wpo-section-title">
                        <span></span>
                        <h2>Our Latest News</h2>
                    </div>
                </div>
                <div className="row">
                    {latestBlogs.map((blog) => (
                        <div className="col-lg-4 col-md-6 col-12" key={blog.id}>
                            <div className="blog-item">
                                <div className="blog-img">
                                    <img src={blog.image} alt={blog.title} loading="lazy" />
                                </div>
                                <div className="blog-content">
                                    <ul className="post-meta">
                                        <li>
                                            <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>
                                                {blog.category.join(', ')}
                                            </Link>
                                        </li>
                                        <li>{blog.date}</li>
                                    </ul>
                                    <h3>
                                        <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>
                                            {blog.title}
                                        </Link>
                                    </h3>
                                    <Link onClick={ClickHandler} to={`/blog-details/${blog.id}`}>Continue</Link>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default BlogSection;
