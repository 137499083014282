// src/components/Hero/index.js

import React, { Component } from "react";
import Slider from "react-slick";
//import { Link } from 'react-router-dom'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import './style.css'

class Hero extends Component {
    render() {
        var settings = {
            dots: false,
            arrows: false,
            speed: 0,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: false,
            autoplaySpeed: 0,
            fade: true,
            adaptiveHeight: false, // Prevents slider from adjusting height based on content
            variableWidth: false, // Prevents slider from adjusting width based on content
            infinite: true,
            // Ensure responsive settings maintain full width
            responsive: [
                {
                    breakpoint: 768,
                    settings: {
                        arrows: false, // Hides arrows on smaller screens for better UX
                        dots: false, // Hides dots on smaller screens for better UX
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        autoplay: false,
                        autoplaySpeed: 2500,
                        fade: true,
                    }
                }
            ]
        };
        return (

            <section className={`hero ${this.props.heroClass}`}>
                <div className="hero-slider">
                    <Slider {...settings}>
                        <div className="slide">
                            <div className="slide-inner" style={{ backgroundImage: `url(${this.props.heroImg1})` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col col-lg-8 col-md-12 col-12 slide-caption">
                                            <div className="slide-title">
                                                {"As Soon As You Land"}
                                            </div>
                                            <div className="slide-subtitle">
                                                <p>{"We Look After You..."}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide">
                            <div className="slide-inner" style={{ backgroundImage: `url(${this.props.heroImg2})` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col col-lg-8 col-md-12 col-12 slide-caption">
                                            <div className="slide-title">
                                                {/* Add your title here */}
                                            </div>
                                            <div className="slide-subtitle">
                                                <p>{/* Add your subtitle here */}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="slide">
                            <div className="slide-inner" style={{ backgroundImage: `url(${this.props.heroImg3})` }}>
                                <div className="container">
                                    <div className="row">
                                        <div className="col col-lg-8 col-md-12 col-12 slide-caption">
                                            <div className="slide-title">
                                                {/* Add your title here */}
                                            </div>
                                            <div className="slide-subtitle">
                                                <p>{/* Add your subtitle here */}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </Slider>
                </div>
            </section>
        );
    }
}
export default Hero;
