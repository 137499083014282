import React from 'react';
import { Link } from 'react-router-dom';
import dsn1 from '../../images/destination/bangkok.jpeg';
import dsn2 from '../../images/destination/chiangmai.jpeg';
import dsn3 from '../../images/destination/phiphi.jpeg';
import dsn4 from '../../images/destination/sukhothai.jpeg';

import './style.css';

// Destination data array
const destinations = [
  {
    id: 1,
    image: dsn1,
    link: 'https://booking.ogtix.asia/bus/bangkok/phuket/6056',
    title: 'Bangkok ↔ Phuket',
    frequency: 'Daily',
    price: '680THB',
  },
  {
    id: 2,
    image: dsn2,
    link: 'https://booking.ogtix.asia/bus/bangkok/chiang-mai/3494',
    title: 'Bangkok ↔ Chiang Mai',
    frequency: 'Daily',
    price: '595THB',
  },
  {
    id: 3,
    image: dsn3,
    link: 'https://booking.ogtix.asia/bus/phuket/surat-thani/30843',
    title: 'Phuket ↔ Surathani',
    frequency: 'Daily',
    price: '250THB',
  },
  {
    id: 4,
    image: dsn4,
    link: 'https://booking.ogtix.asia/bus/sukhothai/bangkok/42414',
    title: 'Bangkok ↔ Sukhothai',
    frequency: 'Daily',
    price: '365THB',
  },
  // Add more destinations as needed
];

const Destination = () => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  return (
    <div className="destination-area">
      <div className="container">
        <div className="row">
          {/* Optional Section Title */}
          <div className="col-12">
            <div className="wpo-section-title">
              <h2>Popular Destinations</h2>
            </div>
          </div>
        </div>
        <div className="row">
          {destinations.map((destination) => (
            <div key={destination.id} className="col-sm-6 col-md-4 col-lg-3">
              <a
                href={destination.link}
                target="_blank"
                rel="noopener noreferrer"
                onClick={ClickHandler}
                className="destination-link"
              >
                <div className="destination-item">
                  <div className="destination-img">
                    <img src={destination.image} alt={destination.title} />
                  </div>
                  <div className="destination-content">
                    <div className="content-left">
                      <h5>{destination.title}</h5>
                      <small>{destination.frequency}</small>
                    </div>
                    <div className="content-right">
                      <h5>{destination.price}</h5>
                      <span>Starting</span>
                    </div>
                  </div>
                </div>
              </a>
            </div>
          ))}
        </div>
        <div className="btns text-center">
          <Link onClick={ClickHandler} to="/destination" className="theme-btn-s2">
            Discover More
          </Link>
        </div>
      </div>
    </div>
    
  );
};

export default Destination;
